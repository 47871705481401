<template>
  <div class="container-1" style="width: 80vw; margin:auto">
    <div class="about-block">
      <img src="../assets/dinnerparty.jpg" class="float-right" alt="聚餐">
      <br>
      <div>
      <h4>加入我們</h4>
      <a href="mailto:ntuc2l2@gmail.com" target="_blank">
      <img src="https://img.icons8.com/?size=100&id=63489&format=png&color=000000" alt="email" class="icon-about">
      </a>
      <span> 專任 / 兼任研究助理</span>
      <br>
      </div>

    </div>

    <div class="about-block">
      <img src="../assets/office.jpg" class="float-right">
      <br>
      <div>
      <h4>工作環境</h4>
      
      <a href="https://maps.app.goo.gl/a1TctvqGwyK89Bnj6" target="_blank">
      <img src="https://img.icons8.com/?size=25&id=43731&format=png&color=000000" class="icon-about">
      </a>
      <span>國立臺灣大學鄭江樓</span>
      <br>
      </div>
    </div>

    <div class="about-block">
      <img src="../assets/meeting.jpg" class="float-right">
      <br>
      <div>
      <h4>學術深造</h4>

      <p></p>
    </div>
  </div>
  </div>
</template>


<script>
export default {
  name: 'aboutPage',
}
</script>

<style scoped>
  @media (max-width: 668px) {
    .about-block{
      margin: auto;
      height: 300px;
      /* border: 2px solid red ; */

    }
    .about-block>div{
      margin:5vh;
    }
    .float-right{
      width: 300px;
      /* object-fit: fill; */
      height: 200px;
      margin: auto;
    }
    .icon-about{
      width: 25px;
      height: auto;
    }
  }

  @media (min-width: 669px) and (max-width: 768px) {
    .about-block{
      margin: auto;
      height: 200px;
      /* width: 1000px; */
    }
    .about-block>div{
      margin:5vh;
    }
    .float-right{
      width: 350px;
      object-fit: fill;
      height: 200px;
      margin: auto;
      margin-bottom: 5vh;
    }
    .icon-about{
      width: 25px;
      height: auto;
    }
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .about-block{
      display:flex;
      margin:5vh;
    }
    .about-block>div{
      margin:1%;
      width: 30vw;
    }
    .float-right{
      width: 300px;
      height: 200px;
    } 
    .icon-about{
      width: 30px;
      height: auto;
    }

  }

  @media (min-width: 1025px) {
    .about-block{
      display:flex;
      margin:5vh;
    }
    .about-block>div{
      margin:1%;
      width: 30vw;
    }
    .float-right{
      width: 30vw;
      height: 36vh;
    } 
    .icon-about{
      width: 30px;
      height: auto;
    }

  }


</style>
