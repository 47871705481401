<template>
  <div class="container">
    <!-- Add your research page content here -->
    <div class="tags-filter">
      <span 
          v-for="tag in availableTags" 
          :key="tag" 
          @click="toggleTagFilter(tag)" 
          :class="['tag', { 'active': activeTags.includes(tag) }]">
          # {{ tag }}
        </span>
    </div>
    <div style="background-color:grey; height:1px; width:80%;margin:1em;"></div>
    
  <div class="list-group-container">
    <b-list-group id="reserach-list">
      <router-link
        v-for="article in filteredArticles"
        :key="article.id"
        :to="{ path: `/page/${article.title}`, query: { id: article.id, date: article.date, author: article.author} }"
        class="list-group-item list-group-item-action custom-list-item">

      <b-list-group-item class="flex-column align-items-start">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{ article.title }}</h5>
          <small>{{ article.date }}</small>
        </div>
        <p class="mb-1" style="color:#007799">{{article.author.join(', ')}}</p>
        <span v-for="tag in article.tag" :key="tag">{{ tag }} </span>
      </b-list-group-item>
  
      </router-link>
    </b-list-group>
  </div>

    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" aria-label="Previous" @click.prevent="prevPage">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li
          class="page-item"
          :class="{ active: currentPage === page }"
          v-for="page in totalPages"
          :key="page"
        >
          <a class="page-link" href="#" @click.prevent="setPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" aria-label="Next" @click.prevent="nextPage">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'researchPage',
  data() {
    return {
      articles: [],
      availableTags: ["產學合作", "健保資料", "財稅資料","其他"],
      activeTags: [],
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  mounted() {
    const url = process.env.VUE_APP_URL;
    const cachedArticles = localStorage.getItem('articles');
    const cacheTime = localStorage.getItem('cacheTime');
    const now = new Date().getTime();
    if (cachedArticles && cacheTime && (now - cacheTime < 3600000)) {
      this.articles = JSON.parse(cachedArticles);
    }else{
    axios.get(`${url}/api/notiondata/research`)
      .then(response => {
        this.articles = response.data;
        localStorage.setItem('articles', JSON.stringify(this.articles));
        localStorage.setItem('cacheTime', new Date().getTime());
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
  },
  computed: {
    filteredArticles() {
      let filtered = this.articles;

      if (this.activeTags.length > 0) {
        filtered = filtered.filter(article =>
          this.activeTags.some(tag => article.tag.includes(tag))
        );
      }

      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return filtered.slice(start, end);
    },
    totalPages() {
      let filtered = this.articles;
      if (this.activeTags.length > 0) {
        filtered = filtered.filter(article =>
          this.activeTags.every(tag => article.tag.includes(tag))
        );
      }
      return Math.ceil(filtered.length / this.itemsPerPage);
    }
  },
  methods: {
    setPage(page) {
      this.currentPage = page;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToTemplate(title) {
      this.$router.push({ path: `/page/${title}` });
    },
    toggleTagFilter(tag) {
      if (this.activeTags.includes(tag)) {
        this.activeTags = this.activeTags.filter(t => t !== tag);
      } else {
        this.activeTags.push(tag);
      }
      this.currentPage = 1; // Reset to the first page after filtering
    },
  }
};
</script>

<style scoped>
/* Your research page styles here */
@media (max-width: 768px) {
  #reserach-list h5{
    font-size: medium;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  }

@media (min-width: 769px) and (max-width: 1024px) {
  #reserach-list h5{
    font-size: large;
  }

  }

@media (min-width: 1025px) {
  #reserach-list h5{
    font-size: large;
  }

  }
.container {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items:center; 
}
.list-group-container {
  min-height: 90vh; /* Adjust this height as needed */
  max-height: 90vh; /* Adjust this height as needed */
  overflow-y: auto;
}
.list-group-item.custom-list-item {
  border: none;
}
#reserach-list{
  width: 80vw;
}
#reserach-list h5{
  font-family: Georgia;
  font-weight: bold;
}

#reserach-list span{
  padding: 0.2rem 0.6rem;
  margin: 0.3rem;
  font-size: 12px;
  color:white;
  background-color:#2894FF;
  border-radius: 20px;
}
#reserach-list h5{
  width:90%;

}
.tag {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  background-color: #f1f1f1;
  border-radius: 20px;
  cursor: pointer;
}
.tag.active {
  background-color: #009393;
  color: white;
}
</style>
