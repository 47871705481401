<template>
  <div class="container">
    <br>
    <div v-if="loading" class="loader">
    </div>
    <div v-else>
    <h2>{{ title }}</h2>
    <div style="text-align:right;width:75vw">
    <br>
    <span><small>{{date}}</small></span>
    <br>
    <small>{{formatAuthors(author)}} </small>
    <br>
    <small v-if="download_url"><a :href="download_url"  target="_blank" >全文下載</a></small>
    </div>

    <br>

    <div v-for="(item, index) in content" :key="index" class='content'>

    <div v-if= "item.type==='paragraph'">
      <p v-for="(textItem, textIndex) in item.rich_text" :key="textIndex">{{ textItem.plain_text }}</p> 
    </div>

    <div v-if= "item.type==='image'" class="article-img-container">
    <img :src="getImageUrl(item)" class="article-img">
    </div>

    <div v-if= "item.type==='iframe'" class="article-img-container">
    <iframe :src="getIframeUrl(item)" width="600" height="400" seamless frameborder="0" scrolling="no"></iframe>
    </div>

    <div v-if= "item.type==='file'" class="article-img-container">
    <iframe :src="getImageUrl(item)" width="1200" height="400" style="border:2px solid grey;" ></iframe>
    </div>
    

    </div>
  </div>
  </div>
    
</template>

<script>
import axios from 'axios';
export default {
  name:'TemplatePage',
  props: ['title','id','date','author'],
  data() {
    return {
      download_url:'',
      content:[],
      loading: true, // 初始化loading状态
    };
  },
  mounted() {
    const url = process.env.VUE_APP_URL;
    axios.get(`${url}/api/notiondata/page/`+this.id)
      .then(response => {
        this.download_url = response.data[0];
        this.content = response.data[1];
        this.loading = false;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        this.loading = false;
      });
  },
  methods: {
    getImageUrl(element) {
      if (element.type === 'image'||element.type === 'file') {
        const image_file =  element.file;
        return image_file.url
      }
      return '';
    },
    getIframeUrl(element) {
      if (element.type === 'iframe') {
        const image_file =  element.url;
        return image_file
      }
      return '';
    },
    getIframeWidth(element) {
      if (element.type === 'iframe') {
        const width =  element.width;
        return width
      }
      return '';
    },
    getIframeHeight(element) {
      if (element.type === 'iframe') {
        const height = element.height;
        return height
      }
      return '';
    },
    formatAuthors(author) {
      if (Array.isArray(author)) {
        if (author.length > 1) {
          return author.join(', ');
        }
        else if (author.length === 1) {
          return author[0];
        }
      }
      else{
        return author;
      }
    }    

  }
};
</script>
<style>

.container h2{
  text-align:center;
  font-family: Georgia;
  font-weight: bold;
}
.article-img-container{
  display:flex;
  justify-content: center;
  margin:5px;
}
.article-img{
  width:600px;
}
.content{
  margin:auto;
  padding:1%;
  width:60vw;
}
/* HTML: <div class="loader"></div> */
.loader {
  position: relative;
  top: 10rem;  
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  display: inline-grid;
  
}
.loader:before,
.loader:after {
  content: "Loading...";
  grid-area: 1/1;
  line-height: 1em;
  -webkit-mask: linear-gradient(90deg,#000 50%,#0000 0) 0 50%/2ch 100%;
  -webkit-mask-position: calc(var(--s,0)*1ch) 50%;
  animation: l30 2s infinite;
}
.loader:after {
  --s:-1;
}
@keyframes l30 {
   33%  {transform: translateY(calc(var(--s,1)*50%));-webkit-mask-position:calc(var(--s,0)*1ch) 50%}
   66%  {transform: translateY(calc(var(--s,1)*50%));-webkit-mask-position:calc(var(--s,0)*1ch + 1ch) 50%}
   100% {transform: translateY(calc(var(--s,1)*0%)); -webkit-mask-position:calc(var(--s,0)*1ch + 1ch) 50%}
}
</style>