<template>
  <div id="app">
    <Nav />
    <br>
    <!-- Your main content goes here -->
    <router-view></router-view>
  </div>
</template>

<script>
import Nav from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Nav,
  }
}
</script>

<style>
  @media (max-width: 768px) {
    
    h1{
    font-size: larger;
  }
  h2{
    font-size: large;
    }
  h3{
    font-size: medium;
  }
  h4{
    font-size: medium;
  }
  h5{
    font-size: small;
  }
 p{
    font-size: x-small;
  }


 
  }

  @media (min-width: 769px) and (max-width: 1024px) {

  }

  @media (min-width: 1025px) {

  }


  h1{
    font-size: xx-large;
  }
  h2{
    font-size: x-large;
    }
  h3{
    font-size: larger;
  }
  h4{
    font-size: large;
  }
  h5{
    font-size: medium;
  }
 p{
    font-size: medium;
  }


</style>
