// main.js

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from './components/HomePage.vue';
import MemberPage from './components/MemberPage.vue';
import AboutPage from './components/AboutPage.vue';
import AchievementsPage from './components/AchievementsPage.vue';
import ResearchPage from './components/ResearchPage.vue';
import TemplatePage from './components/TemplatePage.vue';
import AlumniPage from './components/AlumniPage.vue';

Vue.use(VueRouter)

const routes = [
    { path: '/', component: HomePage },
    { path: '/members', component: MemberPage },
    { path: '/alumni', component: AlumniPage },
    { path: '/achievement', component: AchievementsPage },
    { path: '/research', component: ResearchPage },
    { path: '/about', component: AboutPage },
    { path: '/page/:title', component: TemplatePage, props:route => ({ title: route.params.title, id: route.query.id, date: route.query.date, author:route.query.author}) }
  ];

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router;