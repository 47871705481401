<template>
    <div class="container">
      <br/>
      <div v-if="loading" class="loader">
      </div>
      <div v-else>
      <br/>

      <h4>校友</h4>
      <span 
            v-for="year in al_graduation_year" 
            :key="year" 
            @click="al_toggleYearFilter(year)"
            :class="['year', { 'active': al_active === year }]" >
            {{ year }}
            <span> / </span>
          </span>
      <br/>
      <div id='Alumni' class="member-type">
        <div v-for="(person, index) in isAlumni" :key="index" class="person-info" @click="showModal(person)">
          <img v-lazy="person['個人照連結']" alt="To be added..." class="person-image" />
          <div class="person-details">
          <h6>{{ person['中文名字'] }}</h6>
          <h6>{{ person['英文名字'] }}</h6>
          <h6>{{ person['系級'] }}</h6>
        </div>
        </div>
      </div>
      <hr/>
  
    </div>
  
      <!-- Modal -->
      <b-modal v-if="selectedMember" @hide="selectedMember = null" title="成員詳情" id="member-modal" ok-only ok-variant="secondary" ok-title="Close" hide-header-close>
        <div class="d-block text-center">
          <img :src="selectedMember['個人照連結']" alt="To be added..." class="person-image-modal"/>
          <h3>{{ selectedMember['中文名字'] }} ({{ selectedMember['英文名字'] }})</h3>
          <h6>{{ selectedMember['稱謂'] }}</h6>
          <h6>{{ selectedMember['系級'] }}</h6>
          <p>個人簡介: {{ selectedMember['個人簡介'] }}</p>
          <p>負責專案: {{ selectedMember['負責專案'] }}</p>
        </div>
      </b-modal>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { BModal } from 'bootstrap-vue';
  
  export default {
    name: 'MemberPage',
    components: {
      BModal
    },
    data() {
      return {
        loading: true,
        members: [],
        al_graduation_year: [2022, 2023, 2024],
        al_active: 2023,
        selectedMember: null
      };
    },
    mounted() {
      const url = process.env.VUE_APP_URL;
      axios.get(`${url}/api/gsdata`)
        .then(response => {
          this.members = JSON.parse(response.data.members);
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          this.loading = false;
        });
    },
    computed: {
      isAlumni() {
        return [...this.members].filter(member => member['是否為校友'] ==="TRUE" && member['畢業年份'] === this.al_active);
      },
  
    },
    methods: {
      showModal(member) {
        this.selectedMember = member;
        this.$root.$emit('bv::show::modal', 'member-modal');
      },
      al_toggleYearFilter(year) {
        if (this.ft_active === year) {
          this.al_active = null;
        } else {
          this.al_active = year;
        }
      }
    }
  };
  </script>
  
  <style>
    @media (max-width: 768px) {
    .person-image {
        width: 120px;
        height: 180px;
    }
    .person-info p{
        min-width: 120px;
        max-width: 180px;
        white-space: pre;
    }
    .person-image-modal {
        width: 240px;
        height: 360px;
  }
   
   
    }
  
    @media (min-width: 769px) and (max-width: 1024px) {
      .person-image {
        width: 150px;
        height: 225px;
    }
    .person-image-modal {
        width: 240px;
        height: 360px;
  }
   
    }
  
    @media (min-width: 1025px) {
      .person-image {
        width: 150px;
        height: 225px;
    }
    .person-image-modal {
        width: 240px;
        height: 360px;
  }
  
    }
  
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;  
  }
  .person-info {
    width: min-content;
    text-align: center;
    cursor: pointer;
    margin: 1rem;
  }
  .person-image {
    object-fit: cover;
    border-radius: 5%;
    margin-bottom: 0.5rem;
  }
  .person-info:hover .person-image{
    /* transform: translateY(-0.3rem); */
    /* transform: scale(1.02); */
    border: thick double #59686F;
    /* outline: 0.5rem solid khaki; */
    
  }
  .person-info:hover .person-details{
    transform: translateY(0.3rem);
  }
  
  .member-type {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    text-align: center;
    width: 80vw;
    gap: 5rem;
    overflow-x: scroll;
    margin: 1vw;
  }
  
  .person-image-modal {
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  /* HTML: <div class="loader"></div> */
  .loader { 
    width:100vw;
    text-align:center;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
    display: inline-grid;
  
    
  }
  .loader:before,
  .loader:after {
    content: "Loading...";
    grid-area: 1/1;
    line-height: 1em;
    -webkit-mask: linear-gradient(90deg,#000 50%,#0000 0) 0 50%/2ch 100%;
    -webkit-mask-position: calc(var(--s,0)*1ch) 50%;
    animation: l30 2s infinite;
  }
  .loader:after {
    --s:-1;
  }
  @keyframes l30 {
     33%  {transform: translateY(calc(var(--s,1)*50%));-webkit-mask-position:calc(var(--s,0)*1ch) 50%}
     66%  {transform: translateY(calc(var(--s,1)*50%));-webkit-mask-position:calc(var(--s,0)*1ch + 1ch) 50%}
     100% {transform: translateY(calc(var(--s,1)*0%)); -webkit-mask-position:calc(var(--s,0)*1ch + 1ch) 50%}
  }
  .year {
    display: inline-block;
    margin: 0.2rem;
    cursor: pointer;
  }
  .year > span{
    font-weight: bold;
  }
  .year.active {
    font-weight: bold ;
  }
  </style>
  