// src/main.js

import Vue from 'vue';
import App from './App.vue';
import VueLazyload from 'vue-lazyload';
import BootstrapVue from "bootstrap-vue";
import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import router from './router'; 
import errorImage from './assets/error.001.png';
Vue.use(BootstrapVue);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorImage,
  loading: errorImage,
  attempt: 1
});
new Vue({
  el: '#app',
  router, 
  render: h => h(App)
});
