<template>
  <div>
  <div class="container-1">
    <myCarousel :articles="banner_articles"/>

  </div>
  
  <div class="homepage-block" id="research-block">
  
  <h4>我們的研究</h4>
  <div v-for="(article, index) in research_articles" 
          :key="index"
          id="research-info"
          @click="goToTemplate(article)">
      <h5>{{article.title}}</h5>
  </div>

  </div>

  <div class="homepage-block" >
    <h4>近期成果摘要</h4>
    <div v-if="achievement_loading" class="loader-1">
          <b-spinner></b-spinner>
    </div>
      <div v-else-if="achievement_list" class="scroll_block">
        <div  v-for="(article, index) in achievements_articles" 
          :key="index"
          id="achievement-info"
          @click="goToTemplate(article)">
          {{article.title}}
        </div>
      </div>
      <div v-else class="scroll_block-none" >
        <h5 style="text-align: center;">None</h5>
      </div>
  </div>
    <br>
  <div class="homepage-block">
    <h4>最新消息</h4>
    <div v-if="news_loading" class="loader-1">
      <b-spinner></b-spinner>
    </div>
     <div v-else-if="news_list" class="scroll_block">
     <div v-for="(article, index) in news_articles" 
          :key="index"
          id="news-info"
          @click="goToTemplate(article)">
      {{article.title}}
      </div>
    </div>
    <div v-else class="scroll_block-none">
      <h5 style="text-align: center;">None</h5>
  </div>
  <br>
  
  </div>
</div>


</template>


<script>
import axios from 'axios';
import myCarousel from './Carousel.vue';
export default {
  name: 'CarouselComponent',
  components: {
    myCarousel
  },
  data() {
    return {
      achievement_loading:true,
      news_loading:true,
      achievement_list: false,
      news_list: false,
      banner_articles: [],
      research_articles: [],
      achievements_articles:[],
      news_articles:[] 
    };
  },
  mounted() {
  const url = process.env.VUE_APP_URL;
  const cachedHomeBanner = localStorage.getItem('home_banner');
  const cachedHomeResearch = localStorage.getItem('home_research');
  const cachedHomeAchievement = localStorage.getItem('home_achievement');
  const cachedHomeNews = localStorage.getItem('home_news');
  const cacheHomeTime = localStorage.getItem('home_cache_time');
  const now = new Date().getTime();
  
  if (cachedHomeBanner && cachedHomeResearch && cachedHomeAchievement && cachedHomeNews && cacheHomeTime && (now - cacheHomeTime < 3600000)) {
    this.achievement_loading = false;
    this.news_loading = false;
    this.banner_articles = JSON.parse(cachedHomeBanner);
    this.research_articles = JSON.parse(cachedHomeResearch);
    this.achievements_articles = JSON.parse(cachedHomeAchievement);
    this.news_articles = JSON.parse(cachedHomeNews);
    
    if (this.achievements_articles.length > 0) {
      this.achievement_list = true;
    }
    
    if (this.news_articles.length > 0) {
      this.news_list = true;
    }
  } else {
    axios.get(`${url}/api/notiondata/homepage_banner`)
      .then(response => {
        this.banner_articles = response.data;
        localStorage.setItem('home_banner', JSON.stringify(this.banner_articles));
        localStorage.setItem('home_cache_time', new Date().getTime());
        return axios.get(`${url}/api/notiondata/homepage_our_research`);
      })
      .then(response => {
        this.research_articles = response.data;
        localStorage.setItem('home_research', JSON.stringify(this.research_articles));
        return axios.get(`${url}/api/notiondata/homepage_achievement`);
      })
      .then(response => {
        this.achievements_articles = response.data;
        localStorage.setItem('home_achievement', JSON.stringify(this.achievements_articles));
        this.achievement_loading = false;
        
        if (this.achievements_articles.length > 0) {
          this.achievement_list = true;
        }
        
        return axios.get(`${url}/api/notiondata/homepage_news`);
      })
      .then(response => {
        this.news_articles = response.data;
        localStorage.setItem('home_news', JSON.stringify(this.news_articles));
        this.news_loading = false;
        
        if (this.news_articles.length > 0) {
          this.news_list = true;
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
},
  methods: {
    getImageUrl(element) {
        const image_file =  element.cover;
        if (image_file === null) {
          return "https://i.imgur.com/h6ZVaAQ.jpg";
        }
        return image_file.url;
    },
    goToTemplate(article) {
      this.$router.push({
        path: `/page/${article.title}`,
        query: {
          id: article.id,
          date: article.date,
          author: article.author
        }
      })
  }

}
}
</script>

<style>
  @media (max-width: 768px) {
    #research-info{
      width: 10rem;
      height: 10rem;
    }

    #research-info h5{
    font-size: medium;
  }

  }

  @media (min-width: 769px) and (max-width: 1024px) {
    #research-info{
      width: 15rem;
      height: 15rem;
    }

  }

  @media (min-width: 1025px) {
    #research-info{
      width: 15rem;
      height: 15rem;
    }

  }
  @media (max-height: 769px){
    .homepage-block > .scroll_block {
      max-height:18vh;
      min-height:18vh;
      border-radius:1vw;
}
  }
  @media (min-height: 769px) and (max-height: 1025px) {
    .homepage-block > .scroll_block {
      max-height:18vh;
      min-height:18vh;
      border-radius:1vw;
}
  }
  @media (min-height: 1025px) {
    .homepage-block > .scroll_block {
      max-height:fit-content;
      min-height:fit-content;
      border-radius:1vw;
}
  }


.homepage-block{
  width:60vw;
  margin:auto;
}

#research-block{
  height:fit-content;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y:hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


#research-info{
  display: inline-block;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  text-align: center;
  vertical-align: middle;
  border:2px solid #219ebc;
  border-radius: 50%;
  color:white;
  margin: 1rem;
  cursor: pointer;
}

#research-info h5{
  font-weight: bold;
  font-style: italic;
  position: relative;
  color: #219ebc;
  top: 50%;  
  left: 50%;
  transform: translate(-50%, -50%);
}

#research-info:hover {
background: #219ebc;
border-color: #219ebc !important;
transition: all 0.4s ease 0s;
}
#research-info:hover h5{
color: #ffffff !important;
transition: all 0.4s ease 0s;
}


.homepage-block h4{
  position:sticky;
  top:0;
  left:0;
}
.homepage-block > .scroll_block {
  width: 100%;
  overflow-y: scroll;
  background:	#ebebeb;
  border:2px solid #E0E0E0;
  display:block;
}
.homepage-block > .scroll_block-none {
  width: 100%;
  height: max-content;
  background:	#E0E0E0;
  border:2px solid #E0E0E0;
  display:block;
}
.scroll_block div{
  border-radius:5vw;
  width:100;
  height:fit-content;
  background:	#FFFFFF;
  margin:2%;
  padding-left: 1%;
  padding-right: 1%;
  /* cursor: pointer; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* font-size: 2rem; */
}
.scroll_block > div:hover{
  background-color:#219ebc;
  color: #FFFFFF;
}
.scroll_block div p{
  margin-left:2%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 20px;
  cursor: pointer;
}
.loader-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;

}

</style>
