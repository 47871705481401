<template>
  <b-navbar toggleable="lg" type="light" variant="light">
    <b-container>
      <router-link to="/" class="navbar-brand">
        <img src="../assets/c2l2-logo.png" width="145px" height="50px">
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="justify-content" style="width: 100%;">
            <b-nav-item-dropdown text="所有成員" right class="nav-item-dropdown">
            <b-dropdown-item to="/members" class="b-dropdown-item">成員</b-dropdown-item>
            <b-dropdown-item to="/alumni" class="b-dropdown-item">校友</b-dropdown-item>
          </b-nav-item-dropdown>
            
            <!-- <b-nav-item to="/members" class="nav-item">成員</b-nav-item>
            <b-nav-item to="/alumni" class="nav-item">校友</b-nav-item> -->
            <b-nav-item to="/research" class="nav-item">研究</b-nav-item>
            <b-nav-item to="/achievement" class="nav-item">成果摘要</b-nav-item>
            <b-nav-item to="/about" class="nav-item">關於C2L2</b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav  class="justify-content-end" style="width: 100%;">
          <a href="https://www.facebook.com/NTUBDSRC?locale=zh_TW" target="_blank">
            <img src="https://img.icons8.com/?size=200&id=118466&format=png&color=000000" alt="Facebook" style="width: 2rem; height: 2rem; margin-right:1rem;">
          </a>
          <a href="https://econ.ntu.edu.tw" target="_blank">
            <img src="https://img.icons8.com/?size=200&id=Kqsi3v5rWFW9&format=png&color=000000" alt="web" style="width: 2rem; height: 2rem; margin-right:1.2rem;">
          </a>
          <a href="mailto:ntuc2l2@gmail.com" target="_blank">
            <img src="https://img.icons8.com/?size=100&id=63489&format=png&color=000000" alt="email" style="width: 2rem; height: 2rem;">
          </a>
          </b-navbar-nav>
        



      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'NavbarComponent',
}
</script>

<style>
  @media (max-width: 768px) {

 
  }

  @media (min-width: 769px) and (max-width: 1024px) {
  .dropdown-menu{
  transform: translateX(3rem);
}
 
  }

  @media (min-width: 1025px) {
  .dropdown-menu{
  transform: translateX(3rem);
}

  }
/* Your custom styles here */
.nav-item{
  font-size: medium;
}
.nav-item-dropdown:hover .dropdown-menu {
  display: block;
}


</style>
