<!-- Carousel.vue -->
<template>
    <div id="myCarousel">
      <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      style=""
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
    <b-carousel-slide 
      id = "carousel-slide"
      v-for="(article, index) in articles"
      :key="index"      
    >
    <h3>{{ article.title }}</h3>
      
        <template #img>
          <img
            class="d-block img-fluid w-100 "
            :src="getImageUrl(article)"
            @click="goToTemplate(article)"
            alt="image slot"
          >
          
        </template>
    </b-carousel-slide>

      </b-carousel>
    </div>
  </template>
  
  <script>
  export default {
    name: 'myCarousel',
    props: {
      articles: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        slide: 0,
        sliding: null
      };
    },
    methods: {
      onSlideStart() {
        this.sliding = true;
      },
      onSlideEnd() {
        this.sliding = false;
      },
      getImageUrl(element) {
        const image_file =  element.cover;
        return image_file.url;
    },
    goToTemplate(article) {
      this.$router.push({
        path: `/page/${article.title}`,
        query: {
          id: article.id,
          date: article.date,
          author: article.author
        }
      })
  },
    }
  };
  </script>
  
  <style scoped>
  @media (max-width: 768px) {
    #myCarousel{
    height: 40vh;
  }
    #carousel-slide{
    height: 40vh;
    }
    #carousel-slide img{
    min-height:40vh;
    max-height: 40vh;
    border-radius: 0.5rem;
  }
  #myCarousel h3{
    text-shadow: 0px 0px 2px #000;
    color: white;
    font-size: 1rem;
  }
 
 
  
 
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    #carousel-slide img{
    min-height:40vh;
    max-height: 40vh;
    border-radius: 0.5rem;
  }
  }

  @media (min-width: 1025px) {
    #carousel-slide img{
    min-height:60vh;
    max-height: 60vh;
    border-radius: 0.5rem;
  }
  }

  #myCarousel{
    height: auto;
    width:60vw;
    margin: auto;
    object-fit: cover;
    cursor: pointer;
    
  }
  #carousel-slide{
    height: auto;
    width:60vw;
    object-fit: cover;
  }
  #myCarousel h3{
    text-shadow: 0px 0px 2px #000;
    color: white;
    font-size: x-large;
  }
  </style>
  