<template>
  <div>
    <myCarousel :articles="banner_articles"/>
    <br>
    <div class="achieviement_block">
    <hr>
    <h4>互動式圖文</h4>
    <br>
    <div v-if="articles.length === 0">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
    <b-list-group>
      <b-list-group-item v-for="(article, index) in articles" 
        :key="index"
        id="achievement-info"
        @click="goToTemplate(article)"
        class="d-flex align-items-center">
        <img src="../assets/c2l2-lab-high-resolution-logo-transparent-rectangle.png">
        <div class="ml-3" style="flex: 1;">
          <h5 class="mb-1">{{ article.title }}</h5>
          <p>{{ article.summary.join() }}</p>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import myCarousel from './Carousel.vue';

export default {
  name: 'researchPage',
  components: {
    myCarousel
  },
  data() {
    return {
      banner_articles: [],
      articles :[],
      slide: 0,
      sliding: null
    };
  },
  mounted() {
    const url = process.env.VUE_APP_URL;
    axios.get(`${url}/api/notiondata/achievements_banner`) 
      .then(response => {
        this.banner_articles = response.data;
        
        return axios.get(`${url}/api/notiondata/achievements_articles`);
      })
      .then(response => {
        this.articles = response.data;
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  },
  methods: {
    getImageUrl(element) {
        const image_file =  element.cover;

        return image_file.url;
    },
    goToTemplate(article) {
      this.$router.push({
        path: `/page/${article.title}`,
        query: {
          id: article.id,
          date: article.date,
          author: article.author
        }
      })
  },
  onSlideStart() {
        this.sliding = true
      },
  onSlideEnd() {
        this.sliding = false
      }
}

};
</script>


<style scoped>
@media (max-width: 768px) {
  .achieviement_block h5{
    font-size: medium;
    white-space: normal;
  }
  .achieviement_block{
  width: 60vw;
  margin:auto;
  height:60vh;
  border-radius:1vw;
  overflow-y:scroll;
  /* overflow-x: hidden; */
}
.achieviement_block img{
    width: 60px;
    height: 30px;
  }
  .achieviement_block p {
    display: none;
  }

  }

@media (min-width: 769px) and (max-width: 1024px) {
  .achieviement_block h5{
    font-size: large;
  }
  .achieviement_block{
  width: 60vw;
  margin:auto;
  height:70vh;
  border-radius:1vw;
  overflow-y:scroll;
  overflow-x: hidden;
  }
  .achieviement_block img{
    width: 120px;
    height: 60px;
  }
  .achieviement_block p {
    display: inline;
  }

  }

@media (min-width: 1025px) {
  .achieviement_block h5{
    font-size: large;
  }
  .achieviement_block{
  width: 60vw;
  margin:auto;
  height:70vh;
  border-radius:1vw;
  overflow-y:scroll;
  overflow-x: hidden;
}
.achieviement_block img{
    width: 120px;
    height: 60px;
  }
  .achieviement_block p {
    display: inline;
  }

  }


.achieviement_block > div{
  display:flex;
  justify-content:space-around;
  align-items:center;
  margin:1%;
  padding:1px;
  border-radius:1vw;
  cursor: pointer
}
.achieviement_block h5 {
  font-family: Georgia;
  font-weight: bold;
  margin:1%;
}
.achieviement_block p {
  color: gray;
  margin:1%;
}

</style>
